import { Theme, themr } from '@friendsofreactjs/react-css-themr';
import { routeChange } from '@src/redux-saga-router-plus/actions';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';

import VerticalSeparator from '@client/components/generic/VerticalSeparator';
import PropertyPhotoContainer from '@client/containers/property-photo.container';
import defaultTheme from '@client/css-modules/CHONearbySalesPropertyCard.css';
import { View } from '@client/routes/constants';
import { reportEvent } from '@client/store/actions/analytics.actions';
import { GeoLocation } from '@client/store/sagas/queries/types';
import { NormalizedProperty } from '@client/store/types/property';
import { onEnterOrSpaceKey } from '@client/utils/accessibility.utils';
import { DATE_FORMAT_COMPACT, formatDate } from '@client/utils/date.utils';
import {
  dollarsWithPlaceholder,
  numbersWithPlaceholder,
  placeholderFormatter,
} from '@client/utils/string.utils';

type Props = {
  rel: string;
  theme: Theme;
  slug: string;
  propertyDetails: NormalizedProperty;
  activePropertyLocation: Omit<GeoLocation, 'precision'> | null;
  isActiveListing?: boolean;
};

function CHONearbySalesPropertyCard({
  theme,
  slug,
  propertyDetails,
  activePropertyLocation,
  isActiveListing,
}: Props) {
  const dispatch = useDispatch();
  const {
    fullStreetAddress,
    fullAddress,
    city,
    state,
    sqFt,
    baths,
    beds,
    salePrice,
    listPrice,
    dateOfSale,
    latitude,
    longitude,
    mlsLogoOverlay,
  } = propertyDetails;
  const distance = activePropertyLocation
    ? getDistance(
        activePropertyLocation.latitude,
        activePropertyLocation.longitude,
        latitude,
        longitude
      )
    : null;
  const isShowingSmallDate = !!(listPrice && !salePrice);

  // calculate the distance between two locations
  function getDistance(lat1, lon1, lat2, lon2) {
    if (lat1 === lat2 && lon1 === lon2) {
      return 0;
    } else {
      let radlat1 = (Math.PI * lat1) / 180;
      let radlat2 = (Math.PI * lat2) / 180;
      let theta = lon1 - lon2;
      let radtheta = (Math.PI * theta) / 180;
      let dist =
        Math.sin(radlat1) * Math.sin(radlat2) +
        Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      if (dist > 1) {
        dist = 1;
      }
      dist = Math.acos(dist);
      dist = (dist * 180) / Math.PI;
      dist = dist * 60 * 1.1515;
      return dist.toFixed(1);
    }
  }

  function renderPropertyDetails(
    type,
    formattedValue,
    unitText,
    isShowingVerticalSeparator
  ) {
    if (type) {
      if (isShowingVerticalSeparator) {
        return (
          <div className={theme.CompSalesCardDetailWrapper}>
            <div>
              {formattedValue} {unitText}
            </div>
            <VerticalSeparator theme={theme} />
          </div>
        );
      } else {
        return (
          <div className={theme.CompSalesCardDetailWrapper}>
            <div>
              {formattedValue} {unitText}
            </div>
          </div>
        );
      }
    }
    return '';
  }

  function handleCompSalesCardLinkClick() {
    reportEvent('click_owner_sales_property');
    dispatch(routeChange({ view: View.PROPERTY_DETAILS, params: { slug } }));
  }

  return (
    <div
      data-hc-name="property-card"
      className={theme.CompSalesCard}
      aria-label="Property details"
      onClick={handleCompSalesCardLinkClick}
    >
      <div className={theme.CompSalesCardInner}>
        <div className={theme.CompSalesCardInnerContainer}>
          <div className={theme.CompSalesCardTopDetail}>
            <PropertyPhotoContainer
              photoSize={'SMALL' as 'SMALL'}
              className={theme.CompSalesPropertyPhoto}
              addressSlug={slug}
              fullAddress={fullAddress}
              mlsLogoUrl={mlsLogoOverlay}
              streetViewLocation={
                latitude && longitude
                  ? {
                      latitude: latitude,
                      longitude: longitude,
                    }
                  : null
              }
              shouldUseThumbnailCarousel={false}
            />
            <div className={theme.CompSalesCardSoldDetail}>
              <div
                data-hc-name="property-status"
                className={classNames(theme.CompSalesCardSoldDate, {
                  [theme.CompSalesCardSoldDateSmall]: isShowingSmallDate,
                })}
              >
                {isActiveListing
                  ? 'For Sale'
                  : dateOfSale
                    ? `Sold on ${formatDate(dateOfSale, DATE_FORMAT_COMPACT)}`
                    : 'Sold'}
              </div>
              {!isActiveListing && salePrice ? (
                <div
                  className={theme.CompSalesCardPrice}
                  data-hc-name="property-price"
                >
                  {dollarsWithPlaceholder(salePrice)}
                </div>
              ) : listPrice ? (
                <>
                  {!isActiveListing && (
                    <div className={theme.CompSalesCardListPrice}>
                      Listed for
                    </div>
                  )}
                  <div
                    className={theme.CompSalesCardPrice}
                    data-hc-name="property-price"
                  >
                    {dollarsWithPlaceholder(listPrice)}
                  </div>
                </>
              ) : (
                ''
              )}
            </div>
          </div>
          <div className={theme.CompSalesCardPropertyDetails}>
            <button
              className={theme.CompSalesCardAddress}
              data-hc-name="property-address"
              type="button"
              aria-label={'Navigate to property details'}
              onKeyDown={onEnterOrSpaceKey(handleCompSalesCardLinkClick)}
            >
              {fullStreetAddress}, {city}, {state}
            </button>
            <div
              className={theme.CompSalesCardBasicInfo}
              data-hc-name="property-info"
            >
              {renderPropertyDetails(
                beds,
                placeholderFormatter(beds),
                'Bed',
                true
              )}
              {renderPropertyDetails(
                baths,
                placeholderFormatter(baths),
                'Bath',
                true
              )}
              {renderPropertyDetails(
                sqFt,
                numbersWithPlaceholder(sqFt),
                'Sq. Ft.',
                true
              )}
              {renderPropertyDetails(distance, distance, 'miles away', false)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default themr(
  'ThemedCHONearbySalesPropertyCard',
  defaultTheme
)(CHONearbySalesPropertyCard);
